<template>
  <modal :name="this.name" classes="modal phone-verification" :minWidth="200" :minHeight="600" :maxWidth="480"
    :scrollable="true" :adaptive="true" :reset="true" width="90%" height="auto" @closed="hide">
    <div class="flex justify-end relative">
      <button @click="this.hide" class="absolute right-0 mr-4 mt-4">
        <img src="@/assets/times-black.svg" alt="">
      </button>
    </div>

    <div class="pb-8">
      <h1 class="mt-3 font-semibold mb-8" style="text-align: center">{{ this.title }}</h1>
      <form class="flex flex-col items-center" @submit.prevent="confirmOTP">
        <div class="flex flex-col">
          <span style="text-align: center" class="mb-4">{{ this.description }}</span>
          <otp-input ref="otpInput" input-classes="otp-input" class="mb-5" style="justify-content:center;" separator=""
            :num-inputs="otpLength" :should-auto-focus="true" :is-input-num="true" @on-complete="handleOnComplete"
            @on-change="handleOnChange" />
        </div>

        <button type="button" class="mt-2 mb-6 underline" @click.prevent="requestCode">Kod gelmedi, tekrar gönder</button>

        <template v-if="'password' == this.type">
          <validation-observer class="flex flex-col" style="width:80%" ref="form">
            <validation-provider class="mb-4" tag="div" name="Yeni Parola" rules="required" vid="password"
              v-slot="{ errors }">
              <input type="password" :disabled="loading || !canConfirm" v-model="password" name="password"
                class="form-special" :class="{ disabled: !canConfirm }" placeholder="Yeni Parola">
              <div class="error-label">{{ errors[0] }}</div>
            </validation-provider>
            <validation-provider class="mb-4" tag="div" name="Yeni Parola (Tekrar)" rules="required|confirmed:password"
              v-slot="{ errors }">
              <input type="password" :disabled="loading || !canConfirm" v-model="passwordConfirm"
                name="password-confirm" class="form-special" :class="{ disabled: !canConfirm }"
                placeholder="Yeni Parola (Tekrar)">
              <div class="error-label">{{ errors[0] }}</div>
            </validation-provider>
          </validation-observer>
        </template>

        <button type="submit" class="btn btn--primary relative" :disabled="loading || !canConfirm"
          @click.prevent="confirmOTP" :class="{ 'ld-over-inverse running': loading == true, 'disabled': !canConfirm }">
          {{ 'password' == this.type ? "Sıfırla" : "Doğrula" }}
          <div class="ld ld-ring ld-spin" v-bind:class="{ 'running': this.loading == true }" v-if="this.loading"></div>
        </button>
      </form>
    </div>

  </modal>
</template>

<script>
import OtpInput from "@bachdgvn/vue-otp-input";
import { mapActions, mapState } from "vuex";
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  components: { OtpInput, ValidationProvider, ValidationObserver },
  name: "phone-verification",
  props: {
    name: {
      type: String
    },
    type: {
      type: String,
      default: "verify"
    },
    title: {
      type: String,
      default: () => null
    },
    description: {
      type: String,
      default: () => null
    },
    otpLength: {
      type: Number,
      default: () => 6
    },
    username: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      completed: false,
      loading: false,
      otpCode: "",
      password: null,
      passwordConfirm: null
    }
  },
  computed: {
    ...mapState(["player"]),
    "canConfirm": function () {
      return this.otpCode.length == this.otpLength
    }
  },
  methods: {
    ...mapActions(['createConfirmationRequest', 'validateConfirmationRequest', 'getPlayerInfo', 'createPasswordReset', 'confirmPasswordReset', 'openLoader', 'closeLoader']),
    requestCode() {
      if ('verify' == this.type) {
        this.loading = true
        this.openLoader()
        this.createConfirmationRequest({ id: this.player.id, type: 'verify' }).then((response) => {
          if (response.data.code === "200.0401") {
            this.loading = false;
            this.$toast.error(response.data.message);
            return;
          }
          this.loading = false;
          this.handleClearInput()
          this.$toast.success('Kod Cep telefonunuza gönderildi');
        }).catch(() => {
          this.loading = false;
          this.$toast.error('Bir problem oluştu lütfen daha sonra tekrar deneyin');
        })
        this.closeLoader()
      }

      if ('password' == this.type && this.username) {
        this.loading = true
        this.openLoader()
        this.createPasswordReset({ username: this.username }).then((response) => {
          if (response.data.code === "200.0401") {
            this.loading = false;
            this.$toast.error(response.data.message);
            return;
          }
          if (response.data.result.set.status === true) {
            this.loading = false;
            this.handleClearInput()
            this.$toast.success('Kod Cep telefonunuza gönderildi');
          } else {
            this.loading = false;
            this.message = response.data.message;
          }
        }).catch(() => {
          this.loading = false;
          this.$toast.error('Bir problem oluştu lütfen daha sonra tekrar deneyin');
        })
        this.closeLoader()
      }
    },
    confirmOTP() {
      if ('verify' == this.type) {
        this.openLoader()
        this.validateConfirmationRequest({
          id: this.player.id,
          type: 'verify',
          data: { 'code': this.otpCode }
        }).then(() => {
          this.getPlayerInfo({ 'id': this.player.id }).then(() => {
            this.loading = false;
            this.$toast.success('Cep telefonunuz başarıyla doğrulandı');
            this.$emit('phone-verified')
            this.hide()
          });
        }).catch((err) => {
          this.handleClearInput()
          this.loading = false;
          this.$toast.error(err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Bir problem oluştu lütfen daha sonra tekrar deneyin');
        })
        this.closeLoader()
      }

      if ('password' == this.type) {
        this.$refs.form.validate().then((isValid) => {
          if (!isValid) return;
          this.openLoader()
          this.confirmPasswordReset({
            id: this.userId,
            type: 'password',
            data: { code: this.otpCode, password: this.password, username: this.username }
          }).then(() => {
            this.loading = false;
            this.$toast.success('Şifreniz başarıyla güncellendi');
            this.hide();
          }).catch((err) => {
            this.handleClearInput()
            this.loading = false;
            this.$toast.error(err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Bir problem oluştu lütfen daha sonra tekrar deneyin');
          })
          this.closeLoader()
        })
      }
    },
    handleOnComplete(value) {
      this.completed = true;
      this.otpCode = value;
      if ('password' != this.type) {
        this.confirmOTP()
      }
    },
    handleOnChange(value) {
      this.otpCode = value;
      if (this.otpCode.length < this.otpLength) {
        this.completed = false;
      }
    },
    hide() {
      this.otpCode = "";
      this.password = null;
      this.passwordConfirm = null;
      this.loading = false;
      this.completed = false;
      this.$modal.hide(this.name)
      this.message = null;
    },
    handleClearInput() {
      this.otpCode = ""
      this.$refs.otpInput.clearInput()
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.password = null;
        this.passwordConfirm = null;
      }
    }
  }
}
</script>

<style scoped></style>