<template>
  <div>
    <modal name="login-and-register-modal" classes="modal" :minWidth="200" :minHeight="400" :maxWidth="480"
      :scrollable="true" :reset="true" :height="'auto'" :adaptive="true" width="90%" @closed="onCloseModal">

      <div class="flex justify-end relative">
        <button @click="$modal.hide('login-and-register-modal')" class="absolute right-0 mr-4 mt-4">
          <img src="@/assets/times-black.svg" alt="">
        </button>
      </div>

      <h2 class="text-center my-4" style="font-size: 24px">{{ 'login' == type ? "GİRİŞ YAP" : "ÜYE OL" }}</h2>

      <!-- <div class="text-center my-4" style="font-size: 24px">
        <button type="button" @click="useEmail = true" :class="{ 'active': useEmail }">
          <img src="@/assets/icon-email.svg" alt="E-Mail" />
        </button>
        <button type="button" @click="useEmail = false" :class="{ 'active': !useEmail }">
          <img src="@/assets/icon-phone.svg" alt="Cep Telefonu" />
        </button>
      </div> -->
      <div class="login-tabs" style="font-size: 24px">
        <button v-if="'login' == type" type="button" class="login-tabs_item" @click="useEmail = true" :class="{ 'isActive': useEmail }">
          <img style="height: 28px" class="mr-1" src="@/assets/icon-email.svg" alt="Login with E-Mail" />
          Email
        </button>
        <button class="login-tabs_item" v-if="'login' == type" type="button" @click="useEmail = false" :class="{ 'isActive': !useEmail }">
          <img style="height: 28px" class="mr-1" src="@/assets/icon-phone.svg" alt="Login with Cep Telefonu" />
          Telefon
        </button>
      </div>
      <div class="mx-auto flex flex-col items-center px-4">
        <p class="mb-4 text-center text-danger" v-html="this.errorMsg"></p>
        <form class="flex flex-col items-center w-full" @submit.prevent="registerAction">
          <validation-observer class="flex flex-col" style="width:80%" ref="form">
            <validation-provider v-if="useEmail" class="mb-4" tag="div" name="E-Mail Adresi" rules="required|email"
              v-slot="{ errors }">
              <input type="text" :disabled="loading" v-model="loginData.username" required name="username"
                class="form-special" placeholder="E-Mail Adresi">
              <div class="error-label">{{ errors[0] }}</div>
            </validation-provider>
            <validation-provider v-else class="mb-4" tag="div" name="Cep Telefonu"
              rules="required|validPhone|validatePhoneNumberLength" v-slot="{ errors }">
              <vue-tel-input type="tel" v-model="loginData.username" mode="international" :invalidMsg="errors[0]"
                :preferredCountries="['TR']" :validCharactersOnly="true" :disabled="loading" :autoFormat="true"
                :dropdownOptions="{
                  showDialCodeInList: true,
                  showFlags: true,
                  showSearchBox: true,
                }" :inputOptions="{
                  type: 'tel',
                  required: true,
                  name: 'username',
                  maxlength: 17,
                  placeholder: 'Cep Telefonu',
                }">
              </vue-tel-input>
              <div class="error-label">{{ errors[0] }}</div>
            </validation-provider>
            <validation-provider tag="div" name="Şifre" rules="required" v-slot="{ errors }">
              <input type="password" :disabled="loading" v-model="loginData.password" required name="password"
                class="form-special" placeholder="Parola">
              <div class="error-label">{{ errors[0] }}</div>
            </validation-provider>
            <div class="text-right mb-4">
              <button type="button" class="mb-3 underline" v-if="'login' == type" @click="clickResetPassword()">Parolamı
                unuttum</button>
            </div>
            <validation-provider v-if="type == 'register'" tag="div" name="Gizlilik Politikası ve KVKK Aydınlatma Metni"
              rules="required" v-slot="{ errors }">
              <div class=" mb-4">
                <label for="kvkk"><input id="kvkk" type="checkbox" v-model="kvkk" :true-value="true" :false-value="null"
                    required name="kvkk" class="mr-1"> <a href="/privacy" class="underline" target="_blank">Gizlilik
                    Politikası ve KVKK Aydınlatma Metnini</a> okudum onaylıyorum.</label>
                <div class="error-label">{{ errors[0] }}</div>
              </div>
            </validation-provider>

            <div class="mb-5 center-content" v-if="loginData.username">
              <RecaptchaComponent :sitekey="siteKey" :callback="onCaptchaSuccess" />
            </div>

            <button type="submit" class="btn btn--primary block mb-5 mx-auto"
              v-bind:class="{ 'ld-over-inverse running': this.loading == true }" v-if="type == 'login'"
              @click.prevent="loginAction">
              Giriş Yap
              <div class="ld ld-ring ld-spin" v-bind:class="{ 'running': this.loading == true }" v-if="loading"></div>
            </button>

            <button type="submit" class="btn btn--secondary block mb-5 mx-auto" v-else
              v-bind:class="{ 'ld-over-inverse running': this.loading == true }" @click.prevent="registerAction">
              Üye Ol
              <div class="ld ld-ring ld-spin" v-bind:class="{ 'running': this.loading == true }" v-if="this.loading">
              </div>
            </button>
          </validation-observer>
        </form>

        <button type="button" class="mb-3 underline" v-if="'login' == type" @click="changeLoginType('register')">Henüz
          üye değilim</button>
        <button type="button" class="mb-3 underline" @click="changeLoginType('login')" v-else>Zaten üyeyim</button>
      </div>


    </modal>

    <reset-password-modal :username="_.cloneDeep(loginData.username)"
      @handleReset="onRequestPasswordReset"></reset-password-modal>

    <verification-modal ref="password-reset-modal" name="password-reset-modal" type="password"
      :username="this.loginData.username" title="Parolanı Sıfırla" description="Size iletilen kodu girin" />

    <verification-modal ref="otp-verification-modal" name="otp-verification-modal" type="register"
      title="Hesabımı Doğrula" description="Size iletilen kodu girin" @phone-verified="showLoggedInMessage" />

  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ResetPasswordModal from './ResetPasswordModal';
import VerificationModal from "@/views/components/VerificationModal"
import RecaptchaComponent from './RecaptchaComponent.vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { isValidPhoneNumber, parsePhoneNumberFromString } from "libphonenumber-js";
import axios from 'axios';

extend('validPhone', {
  validate: (value) => isValidPhoneNumber(value, 'TR'),
  message: 'Lütfen geçerli bir telefon numarası giriniz.',
});
extend('validatePhoneNumberLength', {
  validate: (value) => {
    const phoneNumber = parsePhoneNumberFromString(value, 'TR');
    return phoneNumber && phoneNumber.nationalNumber.length === 10;
  },
  message: 'Telefon numarası 10 haneli olmalıdır.',
});

export default {
  components: { ResetPasswordModal, VerificationModal, ValidationProvider, ValidationObserver, RecaptchaComponent },
  name: "login-and-register-modal",
  data() {
    return {
      type: 'login',
      loginData: {
        username: null,
        password: null
      },
      kvkk: null,
      errorMsg: "",
      loading: false,
      useEmail: true,
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      secretKey: process.env.VUE_APP_RECAPTCHA_SECRET_KEY,
      isCaptchaValidated: false
    }
  },
  computed: {
    ...mapState(["player"]),
  },
  methods: {
    ...mapActions(['login', 'register', 'createConfirmationRequest', 'closeLoader']),
    async onCaptchaSuccess(token) {
      try {
        const response = await axios.post('https://www.google.com/recaptcha/api/siteverify', null, {
          params: {
            secret: this.secretKey,
            response: token,
          }
        });

        if (response.data.success) {
          this.isCaptchaValidated = true;
        } else {
          console.error('Token verification failed:', response);
        }
      } catch (error) {
        console.error('Error verifying reCAPTCHA token:', error);
      }
      this.isCaptchaValidated = true;
    },
    loginAction() {
      this.errorMsg = "";
      this.$refs.form.validate().then(isValid => {
        if (!isValid) {
          return;
        } else if (!this.isCaptchaValidated) {
          this.$toast.error('Lütfen reCAPTCHA doğrulamasını tamamlayın.');
          return;
        }
        this.loading = true;
        this.login(this.loginData).then(response => {
          this.loading = false;
          this.$gtag.event('login', { userId: response.data.result.set.id, source: "loginEmail" });
          this.$OneSignal.setExternalUserId(this.player.id)
          this.$modal.hide("login-and-register-modal");
          if (this.useEmail == false) {
            if (this.player.msisdn && !this.player.isVerifiedMsisdn) {
              this.sendMsisdnVerificationCode();
              this.closeLoader()
              this.$modal.show('phone-otp-verification-modal');
            }
          }
        }).catch(error => {
          this.loading = false;
          this.$toast.error(error.response.data.message);
        });
      })
    },
    registerAction() {
      this.errorMsg = "";
      this.$refs.form.validate().then((isValid) => {
        if (!isValid) {
          return;
        } else if (!this.isCaptchaValidated) {
          this.$toast.error('Lütfen reCAPTCHA doğrulamasını tamamlayın.');
          return;
        }
        this.loading = true;
        this.register(this.loginData).then(() => {
          this.loading = false;
          this.sendMsisdnVerificationCode();
          this.openVerificationModal();
        }).catch(error => {
          this.errorMsg = error.response.data.message
          this.$toast.error(error.response.data.message);
          this.loading = false;
        });
      })
    },
    sendMsisdnVerificationCode() {
      this.createConfirmationRequest({ id: this.player.id, type: 'register' }).then((response) => {
        if (response.data.code === "200.0401") {
          this.loading = false;
          this.$toast.error(response.data.message);
          return;
        }
        this.loading = false;
        if (this.useEmail) {
          this.$toast.success('Kod E-Mail adresinize gönderildi');
        } else {
          this.$toast.success('Kod Cep telefonunuza gönderildi');
        }
      }).catch(() => {
        this.loading = false;
        this.$toast.error('Bir problem oluştu lütfen daha sonra tekrar deneyin');
      })
    },
    onRequestPasswordReset(e) {
      this.loginData.username = e.username
      this.$modal.hide('reset-password-modal')
      this.$modal.show('password-reset-modal')
    },
    openVerificationModal() {
      this.$modal.hide("login-and-register-modal");
      this.$modal.show("otp-verification-modal");
    },
    showLoggedInMessage() {
      this.$toast.success('Kayıt işlemi Başarıyla gerçekleşti');
    },
    clickResetPassword() {
      this.$modal.show('reset-password-modal')
      this.$modal.hide("login-and-register-modal")
    },
    changeLoginType(type) {
      this.$refs.form.reset();
      this.type = type
    },
    changeScreen(screen) {
      this.currentScreen = screen
    },
    onCloseModal() {
      this.loginData = {
        username: null,
        password: null
      };
      this.isCaptchaValidated = false;
    },
    handleClearInput() {
      this.$refs.otpInput.clearInput()
      if (this.$refs.form) {
        this.$refs.form.reset();
        this.password = null;
        this.passwordConfirm = null;
      }
    }
  }
}
</script>
<style scoped>
.active {
  font-weight: bold;
}

button.active img {
  border: 2px solid #ffb74f;
  border-radius: 50%;
}

.vue-tel-input {
  border-radius: 8px;
  display: flex;
  border: 4px solid black;
  text-align: left;
}

.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>