<template>
  <div>
    <modal
        name="payment-method-modal"
        classes="modal"
        :minWidth="200"
        :minHeight="400"
        :maxWidth="480"
        :scrollable="true"
        :adaptive="true"
        :reset="true"
        width="100%"
        @before-open="onBeforeOpen"
        @closed="handleClose"
        height="auto">
      <!-- <div class="flex items-center">
        <button class="btn-tab" v-bind:class="{'active' : this.componentName === 'StripeComponent'}" @click="openStripe">Kredi Kartı</button>
      </div> -->
      <div class="flex justify-end relative">
        <button @click="$modal.hide('payment-method-modal')" class="absolute right-0 mr-4 mt-4 z-50">
          <img src="@/assets/times-black.svg" alt="">
        </button>
      </div>
      <div class="flex justify-center items-center relative w-full">
        <h1 class="font-semibold text-center py-4 flex-shrink-0">ÖDEME</h1>
      </div>
      <div class="flex items-center justify-between px-8 py-4" v-if="product">
        <div class="flex item-center">
          <img :src="product.image" :alt="product.title" style="min-height: 60px">
          <div class="flex flex-col ml-2">
            <template v-if="product.type == 'c' && product.rewardInfo.coins > 0">
              <div class="text-2xl font-semibold">{{setDotInNumber(product.rewardInfo.coins)}}</div>
              <div class="text-sm">KOİN PAKETİ</div>
            </template>
            <template v-if="product.type == 'r' && product.rewardInfo.coins > 0">
              <div class="text-2xl font-semibold">{{setDotInNumber(product.rewardInfo.coins)}}</div>
              <div class="text-sm">KOİN PAKETİ</div>
            </template>
            <template v-if="product.type == 'r' && product.rewardInfo.powerups.change > 0">
              <div class="text-2xl font-semibold">{{setDotInNumber(product.rewardInfo.powerups.change)}}x</div>
              <div class="text-sm">PAS GÜCÜ</div>
            </template>
            <template v-if="product.type == 'r' && product.rewardInfo.powerups.open > 0">
              <div class="text-2xl font-semibold">{{setDotInNumber(product.rewardInfo.powerups.open)}}x</div>
              <div class="text-sm">HARF AÇMA GÜCÜ</div>
            </template>
            <template v-if="product.type == 'r' && product.rewardInfo.powerups.rewind > 0">
              <div class="text-2xl font-semibold">{{setDotInNumber(product.rewardInfo.powerups.rewind)}}x</div>
              <div class="text-sm">GERİ ALMA GÜCÜ</div>
            </template>
            <template v-if="product.type == 'r' && product.rewardInfo.powerups.hint > 0">
              <div class="text-2xl font-semibold">{{setDotInNumber(product.rewardInfo.powerups.hint)}}x</div>
              <div class="text-sm">İPUCU GÜCÜ</div>
            </template>
            <template v-if="product.type == 'r' && product.rewardInfo.powerups.ticket > 0">
              <div class="text-2xl font-semibold">{{setDotInNumber(product.rewardInfo.powerups.ticket)}}x</div>
              <div class="text-sm">BİLET</div>
            </template>
            <template v-if="product.type == 'r' && product.rewardInfo.powerups.immortality.isActive === true">
              <div class="text-2xl font-semibold"><small>+</small> {{getImmortalityLabel(product.rewardInfo.powerups.immortality.validUntil)}}</div>
              <div class="text-sm">BİLET</div>
            </template>
          </div>
        </div>
        <div class="flex flex-col items-end">
          <div class="text-2xl font-semibold">{{productTL}}<span class="font-normal text-base ml-1">₺</span></div>
          <div class="text-sm">(<span class="mr-1">{{product.price}}</span>{{productCurrency}})</div>
        </div>
      </div>

      <template v-if="product && product.rewardInfo.extraInternet">
        <div class="flex items-center justify-center border-b-2 border-dashed border-gray-400 pb-4">
          <div class="font-bold bg-black text-white p-2 mx-8 w-full text-center">
            {{ product.rewardInfo.extraInternet.title }} <span class="underline-orange">DAHİL</span>
          </div>
        </div>
      </template>

      <template v-if="product && product.rewardInfo.extraInternet">
          <p v-if="player.isVerifiedMsisdn" class="text-center mt-2">Telefon numaranız ({{ player.msisdn }}) doğrulandı. Satın alım sonrasında internet hediyesi tanımlanacaktır.</p>
          <p v-else class="text-center text-secondary py-4 border-b-2 border-dashed border-gray-400">Ürün gb hediyesi içeridiği için telefon numaranızı doğrulamanız gerekli.</p>
      </template>

      <div class="flex flex-col items-center my-4" v-if="activePaymentMethod == null">
        <GPayComponent v-if="this.transactionId" :product=product @onCallback="(data) => onHandleCallback(data)" :transactionId=this.transactionId />
          <div class="my-4">veya</div>
          <button class="btn btn--xs" style="width: 130px;" @click="onClickCreditCardButton">Kredi Kartı</button>
      </div>
      <div v-else-if="activePaymentMethod == 'c'" class="my-4">
        <div class="cursor-pointer ml-4 inline-flex items-center font-semibold text-lg" @click="onClickBackButton"><img style="height: 32px" class="mr-2" src="@/assets/icon-back.svg" alt=""> Geri</div>
        <component :is="componentName" v-bind="this.props" @onCallback="(data) => onHandleCallback(data)"></component>
      </div>
  
      <div class="flex items-center" style="font-size: 10px; margin:0 10px auto">
        * Ödeyeceğiniz tutar kullanmış olduğunuz kredi kartı ve bankanızın döviz politikaları kaynaklı değişkenlik gösterebilmektedir.
      </div>
      <div class="flex items-center" style="font-size: 10px; margin:0 10px auto">
        ** İnternet paketleri yalnızca Türkiye numaraları için geçerlidir.
      </div>
    </modal>
  </div>
</template>

<script>
import FortumoComponent from "@/views/components/PaymentComponent/FortumoComponent";
import StripeComponent from "@/views/components/PaymentComponent/StripeComponent";
import GPayComponent from "@/views/components/PaymentComponent/GPayComponent";

export default {
  name: "PaymentMethod",
  components: {FortumoComponent, StripeComponent, GPayComponent},
  data() {
    return {
      transactionId: null,
      product: null,
      productCurrency: null,
      productTL: null,
      player: null,
      componentName: "StripeComponent",
      paymentMethods: [],
      activePaymentMethod: null
    }
  },
  computed: {
    props() {
      return {
        transactionId: this.transactionId,
        product: this.product,
        player: this.player,
        url: this.transactionId && this.player.id && this.product.sku ? '524664b9787712ebe88deefa492491dd?operation_reference=' + this.transactionId + '&cuid=' + this.player.id + '&tc_id=' + this.product.sku : null,
        availablePaymentMethods: this.paymentMethods
      }
    },
  },
  watch: {
    'product': function () {
      this.componentName = 'StripeComponent';
    }
  },
  created() {
    this.openStripe();
  },
  methods: {
    onHandleCallback(data) {
      data.transactionId = this.transactionId;
      this.$gtag.purchase({
        "currency": data.product.currency,
        "transaction_id": data.transactionId,
        "value": data.product.price,
        "items": [
          {
            "item_id": data.product.sku,
            "item_name": data.product.title,
            "quantity": 1,
            "price": data.product.price
          }
        ]
      })
      this.$modal.show("result-modal", data);
      this.hide();
      this.$emit('onCallback', data)
    },
    onBeforeOpen(event){
      const payload = event.params;
      if(payload) {
        this.transactionId = payload.transactionId;
        this.product = payload.product;
        this.player = payload.player;
        this.paymentMethods = payload.paymentMethods;
        if(payload.product.currency == 'EUR'){ this.productCurrency = '€' }
        if(payload.product.currency == 'USD'){ this.productCurrency = '$' }
        if(payload.product.currency == 'TL'){ this.productCurrency = '₺' }
        this.productTL = payload.productTL;
      }
    },
    onClickCreditCardButton() {
      this.activePaymentMethod = "c";
    },
    onClickBackButton() {
      this.activePaymentMethod = null;
    },
    setDotInNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    handleClose(){
      this.componentName = 'StripeComponent';
      if(this.$route.name == "game" && this.gameStatus != null) {
        this.$modal.show("game-result-modal")
      }
    },
    show() {
      this.$modal.show("payment-method-modal");
    },
    hide() {
      this.$modal.hide("payment-method-modal");
    },
    openStripe() {
      this.componentName = 'StripeComponent';
    },
    openFortumo() {
        this.componentName = 'FortumoComponent'
    },
    getImmortalityLabel(validUntil) {
      const minReg = /^\+PT(\d+)M$/;
      const hourReg = /^\+PT(\d+)H$/;
      const dayReg = /^\+P(\d+)D$/;
      let label = ''

      if(minReg.test(validUntil)) {
          let duration = validUntil.slice(3, -1)
          label = duration + ' Dk.'
      } else if (hourReg.test(validUntil)) {
          let duration = validUntil.slice(3, -1)
          label = duration + ' Saat'
      } else if (dayReg.test(validUntil)) {
          let duration = validUntil.slice(2, -1)
          label = duration + ' Gün'
      }

      return label
    }
  }
}
</script>

<style scoped>

</style>